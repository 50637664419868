<template>
  <div class="activity wrapper">
    <a-spin :spinning="loading">
      <div>
        <a-empty v-if="activity.length == 0" description="暂无活动"></a-empty>
        <div v-else class="activity-box-main flex flex-between">
          <template v-for="(item, index) in activity" :key="index">
            <div
              class="activity-item flex flex-between"
              @click="goToActivityDetail(item)"
            >
              <div>
                <div class="activity-title">{{ item.activity_title }}</div>
                <div class="activity-time">{{ item.activity_start_time }}</div>
              </div>
              <right-outlined />
            </div>
          </template>
        </div>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="16"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['16', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getActivityList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "Activity",
  components: {
    RightOutlined
  },
  setup() {
    const store = useStore();

    const pageData = reactive({
      activity: [],
      userData: computed(() => getUserData()),
      page: 1,
      limit: 16,
      total: 0,
      loading: false
    });
    const getActivityListFun = () => {
      pageData.loading = true;
      getActivityList({
        page: pageData.page,
        limit: pageData.limit,
        park_id: pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.activity = res.data.activity;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const goToActivityDetail = _item => {
      url.navigateTo("/activity/detail", {
        t: encode(`${_item.activity_id}`)
      });
    };
    watch(
      [
        () => pageData.userData.user_park_id,
        () => pageData.page,
        () => pageData.limit
      ],
      val => {
        getActivityListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      ...toRefs(pageData),
      goToActivityDetail,
      onShowSizeChange
    };
  }
});
</script>
<style lang="less" scoped>
.activity {
  padding-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .activity-box-main {
    flex-wrap: wrap;
    .activity-item {
      width: 570px;
      height: 110px;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;
      .activity-title {
        color: #212531;
        font-size: 18px;
        margin-bottom: 10px;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .activity-park {
        min-width: 80px;
        height: 22px;
        line-height: 22px;
        border-radius: 11px;
        border: solid 1px #1276cb;
        color: #1276cb;
        font-size: 14px;
        text-align: center;
        margin-right: 20px;
      }
      .activity-time {
        color: #212531;
        opacity: 0.7;
        font-size: 16px;
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
